import Vue from 'vue';
import App from './App.vue';
import router from './router';

import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.min.css';


import { state, actions } from '@/store';

import axios from 'axios'
import VueAxios from 'vue-axios'
const http = axios.create();
http.interceptors.request.use(
    (config) => {
        let token = state.token
        if (token) {
            config. headers['Accept'] = 'application/json';
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => { return Promise.reject(error) }
);

http.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response.status;
        if (status === 401) {
            actions.resetAuth();
            router.push({ name: 'login'});
        }
    }
);

Vue.use(VueAxios, http);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false;

//ELEMENT
import element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/es'
Vue.use(element, {locale});

//IMPORTAMOS VUE HIGHCHART, PARA GRÁFICAS
import VueHighcharts from 'vue-highcharts';
//DECLARAMOS LOS SETTINGS GLOBALES DE HIGHCHART:
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more'
import solidGauge from 'highcharts/modules/solid-gauge';
import wordcloud from 'highcharts/modules/wordcloud';
import loadMap from 'highcharts/modules/map';
import Treemap from 'highcharts/modules/treemap';

const   colors = ['white','green','red']

Highcharts.setOptions({
    colors: colors,
    lang: {
        months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        shortMonths: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        weekdays: ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'],
        thousandsSep: '.'
    },
    tooltip: {
        enabled: true,
        shared: true,
        backgroundColor: "#2B2B3C",
        borderWidth: 0,
        valueDecimals: 2,
        //valueSuffix: '%',
        style: {
            color: "#fff",
        },
        dateTimeLabelFormats: {
            day: "%d/%m/%Y",
        },
        //zIndex:20
    },
});
highchartsMore(Highcharts);
solidGauge(Highcharts);
Treemap(Highcharts);
wordcloud(Highcharts);
loadMap(Highcharts);
Vue.use(VueHighcharts);

//MOMENT
const moment = require('moment')
moment.locale('es')
Vue.use(require('vue-moment'), {moment});

Vue.filter('reduceBigNumbers', function(number, decimals) {
    return reduceBigNumbers(number, decimals)
});
Vue.filter('numberFormat', function(number, decimals) {
    return numberFormat(number, decimals)
});

Vue.filter('removeCharacters', function(str) {
    return str.replace(/[^A-Z0-9]+/ig, "_")
});


Vue.filter('randomNumber', function(min, max) {
    return randomNumber(min, max)
});

export function reduceBigNumbers(number, decimals=2) {
    if (number > 1000000 || number < -1000000) {
        return numberFormat(number / 1000000, decimals) + 'M';
    }
    if (number > 1000 || number < -1000) {
        return numberFormat(number / 1000, decimals) + 'K';
    }
    return numberFormat(number, decimals);
}

export function numberFormat(number, decimals = 0) {
    if (number == null) { return 'N/A' }
    number = +number

    if (typeof number == 'number') {
        decimals = (decimals != undefined) ?  decimals : 2
        return parseFloat(number).toLocaleString('en-EN', {
            maximumFractionDigits: decimals,
            minimumFractionDigits: decimals
        })
        .replace(',', '')
        .replace(/,/g, '@')
            .replace('.', ',')
                .replace(/@/g, '.');
        return parseFloat(number.toFixed(parseFloat(decimals))).toLocaleString('en-EN');
    }
    return number
}

new Vue({
    router,
    render: h => h(App)
}).$mount('#app');
