import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

import { Auth } from '@/api/models';
import { state, actions } from '@/store';

import axios from 'axios'
import VueAxios from 'vue-axios'
const http = axios.create();
http.interceptors.request.use(
    (config) => {
        let token = state.token
        if (token) {
            config. headers['Accept'] = 'application/json';
            config.headers['Authorization'] = `Bearer ${ token }`;
        }
        return config;
    },
    (error) => { return Promise.reject(error) }
);

http.interceptors.response.use(
    (response) => response,
    (error) => {
        const status = error.response.status;
        if (status === 401) {
            actions.resetAuth();
            router.push({ name: 'login'});
        }
    }
);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes(authGuard, guestGuard, publicGuard)
});

export default router;

router.beforeEach(async (to, from, next) => {

    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var user = urlParams.get('user');
    var pass = urlParams.get('pass');

    
    // if(user && pass){

    //     axios.post('/login', { email:user, password:pass } ).then(response => {
            
    //         actions.setToken(response.data.token)

    //         http.get('/me').then(response => {

    //             let user = response.data.data
    //             actions.setUser(user)

    //             router.push( {name:'Home'} )
                
    //         })

    //     })

    // } else {


        if (state.token && !state.user) {
            try {
    
                const {data} = await Auth.me();
                actions.setUser(data);
    
            } catch(error) {
                actions.resetAuth();
            }
        }

    // }


    

    next();
});

function beforeEnter (routes, callback) {
    return routes.map(route => {
        return { ...route, beforeEnter: callback };
    });
};

function authGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (!state.token) {
            return next({ name: 'Login' });
        }

        next();
    });
};

function guestGuard (routes) {
    return beforeEnter(routes, (to, from, next) => {
        if (state.token) {
            return next({ name: 'Home' });
        }

        next();
    });
};

function publicGuard(routes) {
    return beforeEnter(routes, (to, from, next) => {
        next();
    });
}


















