<template>
    <div id="app" class="w-screen h-screen text-gray-900">
        <router-view />
    </div>
</template>

<script>
import { Layout } from '@/layouts';
import { actions, state } from '@/store';

export default {
    name: 'App',
    computed:{
        token(){
            return state.token
        }
    },
    mounted(){

        let urlName = window.location.href
        
        let pathname = urlName.split('/')[3].split('?')[0]

        if(pathname != 'screen1' && pathname != 'screen2' && pathname != 'screen3' && pathname != 'screen4' && pathname != 'screen5' && pathname != 'screen6'){
        
            var queryString = window.location.search;
            var urlParams = new URLSearchParams(queryString);
            var user = urlParams.get('user');
            var pass = urlParams.get('pass');

            
            if(user && pass){

                this.axios.post('/login', { email:user, password:pass } ).then(response => {
                    
                    actions.setToken(response.data.token)

                    this.axios.get('/me').then(response => {

                        let user = response.data.data
                        actions.setUser(user)

                       this.$router.push({name:'Home'})
                        
                    })

                })

            }

        }

    }
};

</script>