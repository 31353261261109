import Vue from 'vue';
import { toCookie, toLocal } from './storages';

const store = {
    token: null,
    user: null,
    msgError: null,
    timeRefresh: 600000,
    timeChangeScreen: 900000
};

const state = Vue.observable(store);

toCookie(state, {
    attrs: ['token']
});

toLocal(state,{
    attrs: ['user', 'token']
});

const actions = {
    setToken(token) {
        state.token = token;
    },
    setUser(user) {
        state.user = user;
    },
    resetAuth() {
        state.token = null;
        state.user = null;
    },
    setMsgError(msgError){
        state.msgError = msgError;
    },
    setTimeRefresh(timeRefresh){
        state.timeRefresh = timeRefresh;
    },
    setTimeChangeScreen(timeChangeScreen){
        state.timeChangeScreen = timeChangeScreen
    }
};

export {
    state,
    actions
};