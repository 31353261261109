export default (authGuard, guestGuard, publicGuard) => [
    ...guestGuard([
        {
            path: '/login',
            name: 'Login',
            // meta: { layout: 'fullscreen' },
            component: () => import(/* webpackChunkName: "login" */ '@/pages/login')
        },
        {
            path: '/',
            name: '/',
            // meta: { layout: 'fullscreen' },
            component: () => import(/* webpackChunkName: "login" */ '@/pages/login')
        },
        
    ]),

    ...authGuard([
        {
            path: '/',
            name: 'Home',
            // meta: { layout: 'app' },
            component: () => import(/* webpackChunkName: "home" */ '@/pages/home')
        },
        {
            path: '/select',
            name: 'select',
            // meta: { layout: 'app' },
            component: () => import(/* webpackChunkName: "home" */ '@/pages/selectPage')
        },
        
        {
            path: '/backoffice',
            name: 'backoffice',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/backoffice')
        },
        {
            path: '/3screens',
            name: '3screens',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/3screens')
        },
        {
            path: '/presentation',
            name: 'presentation',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/presentation')
        },
        {
            path: '/screenleft',
            name: 'screenleft',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screenleft')
        },
        {
            path: '/screencenter',
            name: 'screencenter',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screencenter')
        },
        {
            path: '/screenright',
            name: 'screenright',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screenright')
        },
    ]),
    
    ...publicGuard([
        {
            path: '/screen1',
            name: 'screen1',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen1')
        },
        {
            path: '/screen2',
            name: 'screen2',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen2')
        },
        {
            path: '/screen3',
            name: 'screen3',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen3')
        },
        {
            path: '/screen4',
            name: 'screen4',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen4')
        },
        {
            path: '/screen5',
            name: 'screen5',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen5')
        },
        {
            path: '/screen6',
            name: 'screen6',
            component: () => import(/* webpackChunkName: "login" */ '@/pages/screen6')
        },
    ])
];